export const backgroundColor = '#121E34'
export const containerColor = '#000'
export const borderColor = '#4F457C'
export const textColor = '#FFFFFF'
export const subTextColor = '#7E8794'
export const backgroundTextColor = '#F7F9FD'
export const placeholderColor = '#F7F9FD'
export const primaryColor = '#0023FF'
export const secondaryColor = '#73d7c3'
export const thirdColor = '#ff7a00'
export const upColor = '#00E205'
export const downColor = '#F90021'
export const selectedColor = '#aea3ff3d'
export const shadowColor = '#4d515c'
export const progressColor = '#0029FF'