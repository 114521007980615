import { Button } from 'app/components/Button/Button.controller'
import { HomeButton } from 'app/components/HomeButton/HomeButton.view'
import { Timer } from 'app/components/Timer/Timer.controller'
import { Phase } from 'enum/Phase.enum'
import { useNavigate } from 'react-router'
import { IcoProgressState } from 'reducers/icoProgress'
import { Box } from 'styles/components'
import { DISCORD_LINK, LINKEDIN_LINK, MEDIUM_LINK, TELEGRAM_LINK, TWITTER_LINK } from 'utils/links'

// prettier-ignore
import { LandingCustomLinkSocialButton, LandingDiscordButton, LandingDiscordIcon, LandingDiscordText, LandingLabels, LandingLineContainer, LandingParagraph, LandingProgress, LandingProgressBar, LandingProgressContainer, LandingProgressRowText, LandingProgressText, LandingProgressTooltipTriangle, LandingRow, LandingSocialMediaButton, LandingSocialMediaRow, LandingSpace, LandingStyled, LandingTable, LandingTableContainer, LandingTitle, LandingTitleLarger, LandingTooltipBox, LandingTooltipContainer, LandingWarning } from './Landing.style'

interface LandingViewProps {
  icoProgress: IcoProgressState
  saleProgress: number
  max: number
}

export const LandingView = ({ icoProgress, saleProgress, max }: LandingViewProps) => {
  const navigate = useNavigate()
  // TEST TO REMOVE
  // if (process.env.REACT_APP_ENV === 'dev') icoProgress = { phase: 3, kyc: 0, funding: 0 }
  return (
    <LandingStyled>
      <HomeButton />
      <LandingLineContainer>
        {icoProgress.phase === Phase.COMING_SOON && (
          <>
            <LandingTitle>The Cardashift ICO is coming soon!</LandingTitle>
            <LandingSpace>
              <LandingParagraph>
                <div>
                  Our mission is to foster sustainable development with products and services powered by blockchain
                  technologies.
                </div>
                <Timer deadline="Jan 21 2022 12:00:00 GMT+0100 (heure normale d’Europe centrale)" />
                <div>
                  Cardashift has already successfully raised <b>$1.5M in a private sale</b>! Encouraged by this success,
                  we are preparing an ICO to <b>raise $8M in the coming weeks</b>.
                </div>
                <br />
                <b>
                  CLAP tokens will be sold in exchange for ADA. The sale will be conducted in two phases: a Flash Sale
                  and a Public Sale.
                </b>
              </LandingParagraph>
            </LandingSpace>
          </>
        )}

        {icoProgress.phase === Phase.FLASH_SALE && icoProgress.kyc === 1 && icoProgress.funding === 0 && (
          <LandingTitle>The KYC process is now live</LandingTitle>
        )}

        {icoProgress.phase === Phase.FLASH_SALE && icoProgress.funding === 1 && (
          <LandingTitle>Flash Sale is live</LandingTitle>
        )}

        {icoProgress.phase === Phase.FLASH_SALE && icoProgress.kyc === 0 && icoProgress.funding === 0 && (
          <>
            <LandingTitleLarger>
              <div>Flash Sale will start at</div>
              <div>5:08 PM UTC on Thu 27th, January</div>
            </LandingTitleLarger>
            <br />
            <Timer deadline="Jan 27 2022 17:08:00 GMT+0000" />
            <LandingParagraph>
              <a
                href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220127T170800Z%2F20220127T180800Z&location=ico.cardashift.com&text=Cardashift%20Flash%20Sale"
                target="_blank"
                rel="noreferrer"
              >
                <Button text={'Set a Google Calendar Reminder'} />
              </a>
              <br />
              <br />
              <br />
              <div>
                If you have any questions, join{' '}
                <u>
                  <a href="https://discord.gg/hTSWWButZR">our discord</a>
                </u>{' '}
                or reach us at{' '}
                <u>
                  <a href="mailto:ico@cardashift.com">ico@cardashift.com</a>
                </u>
              </div>
            </LandingParagraph>
          </>
        )}

        {icoProgress.phase === Phase.PUBLIC_SALE && icoProgress.funding === 1 && (
          <LandingTitle>Public Sale is live</LandingTitle>
        )}

        {icoProgress.phase === Phase.PUBLIC_SALE && icoProgress.funding === 0 && icoProgress.kyc === 0 && (
          <LandingTitle>Public Sale is coming soon</LandingTitle>
        )}

        {icoProgress.phase === Phase.PUBLIC_SALE && icoProgress.funding === 0 && icoProgress.kyc === 1 && (
          <LandingTitle>ICO Registrations are re-opened</LandingTitle>
        )}

        {(icoProgress.phase === Phase.FLASH_SALE || icoProgress.phase === Phase.PUBLIC_SALE) && (
          <>
            <LandingParagraph>
              Our mission is to foster sustainable development with products and services powered by blockchain
              technologies.
            </LandingParagraph>
            {icoProgress.funding === 1 && (
              <LandingProgressBlock progress={saleProgress} phase={icoProgress.phase} max={max} />
            )}
            {icoProgress.phase === Phase.PUBLIC_SALE && icoProgress.kyc === 1 && icoProgress.funding === 0 && (
              <Box marginTop={24} marginBottom={24}>
                Our flash sale is sold out, be ready for the Public Sale starting on <br />
                <b>Jan 30th at 11:00 AM UTC</b>.
                <br />
                <br />
                <Timer text="KYC Registrations ends in" deadline="Jan 29 2022 23:00:00 GMT+0000" />
                <br />
                <br />
                <i>
                  If you already registered before the Flash Sale, your registration is still effective. You don't have
                  to register again.
                </i>
              </Box>
            )}
            {icoProgress.kyc === 1 && icoProgress.funding === 0 && (
              <>
                <Button text="KYC Registration" kind="third" icon="arrow" onClick={() => navigate('/rules')} />
                <LandingWarning>
                  Please prepare your ID document, <b>scans will be automatically refused</b>, you have to take a
                  picture of the real document.
                </LandingWarning>
              </>
            )}
            {icoProgress.funding === 1 && (
              <Button text="Participate" kind="third" icon="arrow" onClick={() => navigate('/rules')} />
            )}
            {icoProgress.funding === 1 && icoProgress.kyc === 0 && (
              <>
                <LandingWarning>Reminder: Only validated KYC can participate</LandingWarning>
                <div>
                  <br />
                  <br />
                  <br />
                  <div>
                    If you have any questions, join{' '}
                    <u>
                      <a href="https://discord.gg/hTSWWButZR">our discord</a>
                    </u>{' '}
                    or reach us at{' '}
                    <u>
                      <a href="mailto:ico@cardashift.com">ico@cardashift.com</a>
                    </u>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {icoProgress.phase === Phase.PUBLIC_SALE && icoProgress.funding === 0 && icoProgress.kyc === 0 && (
          <LandingParagraph>
            <Box>
              Our flash sale is sold out, be ready for the Public Sale starting on <br />
              <b>Jan 30th at 11:00 AM UTC</b>.
              <br />
              <Timer deadline="Jan 30 2022 11:00:00 GMT+0000" />
              <LandingParagraph>
                <a
                  href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220130T110000Z%2F20220131T110000Z&location=ico.cardashift.com&text=Cardashift%20Public%20Sale"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button text={'Set a Google Calendar Reminder'} />
                </a>
                <br />
                <br />
                <br />
                <div>
                  If you have any questions, join{' '}
                  <u>
                    <a href="https://discord.gg/hTSWWButZR">our discord</a>
                  </u>{' '}
                  or reach us at{' '}
                  <u>
                    <a href="mailto:ico@cardashift.com">ico@cardashift.com</a>
                  </u>
                </div>
              </LandingParagraph>
            </Box>
          </LandingParagraph>
        )}

        {icoProgress.phase === Phase.CLOSED && (
          <>
            <LandingTitle>The Cardashift ICO is sold out!</LandingTitle>
            <LandingProgressBlock progress={saleProgress} phase={icoProgress.phase} max={max} />
            <LandingParagraph>
              Thanks to every participant for this huge success!
              <br /> Follow us on our social media to keep up to date with our latest news.
            </LandingParagraph>
            <LandingSocialMediaRow>
              <LandingDiscordButton href={DISCORD_LINK} target="_blank">
                <LandingDiscordText>Follow us on Discord</LandingDiscordText>
                <LandingDiscordIcon>
                  <use xlinkHref={`/icons/sprites.svg#discord`} />
                </LandingDiscordIcon>
              </LandingDiscordButton>
            </LandingSocialMediaRow>

            <LandingSocialMediaRow>
              <LandingCustomLinkSocialButton href={DISCORD_LINK} target="_blank">
                <LandingSocialMediaButton>
                  <img src="/icons/discord.svg" alt="Icon Telegram" />
                </LandingSocialMediaButton>
              </LandingCustomLinkSocialButton>
              <LandingCustomLinkSocialButton href={TELEGRAM_LINK} target="_blank">
                <LandingSocialMediaButton>
                  <img src="/icons/telegram.svg" alt="Icon Telegram" />
                </LandingSocialMediaButton>
              </LandingCustomLinkSocialButton>
              <LandingCustomLinkSocialButton href={TWITTER_LINK} target="_blank">
                <LandingSocialMediaButton>
                  <img src="/icons/twitter.svg" alt="Icon Twitter" />
                </LandingSocialMediaButton>
              </LandingCustomLinkSocialButton>
              <LandingCustomLinkSocialButton href={MEDIUM_LINK} target="_blank">
                <LandingSocialMediaButton>
                  <img src="/icons/medium.svg" alt="Icon Medium" />
                </LandingSocialMediaButton>
              </LandingCustomLinkSocialButton>
              <LandingCustomLinkSocialButton href={LINKEDIN_LINK} target="_blank">
                <LandingSocialMediaButton>
                  <img src="/icons/linkedin.svg" alt="Icon LinkedIn" />
                </LandingSocialMediaButton>
              </LandingCustomLinkSocialButton>
            </LandingSocialMediaRow>
          </>
        )}

        <LandingTableContainer>
          <LandingTable>
            <LandingLabels>
              <Box paddingLeft={40} />
              <div>Duration</div>
              <div>
                Volume <br />
                for sale
              </div>
              <div>
                Price <br />
                (ADA-CLAP)
              </div>
              <div>Total raised</div>
            </LandingLabels>
            <LandingRow>
              <div>
                <span>1st release Phase</span>
                <span>Flash Sale</span>
              </div>
              <div>10 minutes</div>
              <div>40M CLAP</div>
              <div>1 ADA = 36 CLAP*</div>
              <div>$1,400,000 **</div>
            </LandingRow>
            <LandingRow>
              <div>
                <span>2nd release Phase</span>
                <span>Public Sale</span>
              </div>
              <div>24 hours</div>
              <div>160M CLAP</div>
              <div>1 ADA = 30 CLAP*</div>
              <div>$6,720,000 **</div>
            </LandingRow>
            <Box textAlign="start">
              <p>
                * We reserve the right to modify this rate before the sale in the event that ADA volatility alters our
                forecasts significantly
              </p>
              <p>** This amount may vary depending on the current ADA price</p>
            </Box>
          </LandingTable>
        </LandingTableContainer>
      </LandingLineContainer>
    </LandingStyled>
  )
}

export const LandingProgressBlock = ({ progress, phase }: { progress: number; phase: number; max: number }) => {
  progress = Math.floor(progress * 100) / 100
  return (
    <LandingProgressContainer>
      {/* <LandingFlashSaleTitle>
        {phase === Phase.FLASH_SALE && <span>Flash Sale</span>}
        {phase === Phase.PUBLIC_SALE && <span>Public Sale</span>}
      </LandingFlashSaleTitle> */}
      <LandingProgressRowText>
        <LandingProgressText>
          {phase === Phase.FLASH_SALE && <span>40,000,000 CLAP tokens for sale</span>}
          {phase === Phase.PUBLIC_SALE && <span>160,000,000 CLAP tokens for sale</span>}
        </LandingProgressText>
      </LandingProgressRowText>
      <LandingProgressBar>
        <LandingProgress width={progress > 100 ? 100 : progress} />
      </LandingProgressBar>
      <LandingTooltipContainer left={progress > 100 ? 100 : progress}>
        <LandingTooltipBox>
          <LandingProgressTooltipTriangle src="/images/triangle_2.png" alt="triangle" />
          <LandingProgressText>{progress}% Token Sold</LandingProgressText>
        </LandingTooltipBox>
      </LandingTooltipContainer>
    </LandingProgressContainer>
  )
}
