import Axios from 'axios';
import { KycStatus } from 'enum/KycStatus.enum';
import { ApiResponse } from 'models/ApiResponse.model';

export namespace KycApi {
  export type CreateCheckRequest = {
    investor_id: number,
    investor_data_hash: string,
  }

  export const createCheck = (data: CreateCheckRequest) => {
    return Axios.post<ApiResponse<{ kyc_status: string }>>('/kyc/create-check', data);
  }

  export const getToken = (investorId: number, investorDataHash: string) => {
    return Axios.get<ApiResponse<string>>(`/kyc/token/${investorId}/${investorDataHash}`)
  }

  export const getStatus = (investorId: number, investorDataHash: string) => {
    return Axios.get<ApiResponse<{ status: KycStatus }>>(`/kyc/status/${investorId}/${investorDataHash}`)
  }
}