import { useLocation } from 'react-router'

import { KycErrorView } from './KycError.view'

export const KycError = () => {
  const location = useLocation()
  // @ts-ignore
  const error = location.state && location.state.error

  return <KycErrorView error={error} />
}
