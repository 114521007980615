import { HomeButton } from 'app/components/HomeButton/HomeButton.view'

import { KycProcessCard, KycProcessStyled, KycProcessTitle, KycProcessTxt } from './KycProcess.style'

export const KycProcessView = () => {
  return (
    <KycProcessStyled>
      <HomeButton />
      <KycProcessCard>
        <div>
          <img src="/images/kyc-process.png" alt="" />
        </div>
        <KycProcessTitle>Your KYC is being processed</KycProcessTitle>
        <KycProcessTxt>
          This page will refresh once your KYC is validated,
          <br />
          you should have received a confirmation email.
        </KycProcessTxt>
      </KycProcessCard>
    </KycProcessStyled>
  )
}
