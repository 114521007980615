import styled from 'styled-components/macro'
import { Card, Page } from 'styles'

export const RulesCard = styled(Card)`
  position: relative;

  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`
export const RulesStyled = styled(Page)`
  width: 100%;
  background-image: url('/images/bg-shapes.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
`
export const RulesRuleBlock = styled.div`
  margin-top: 32px;
`

export const RulesSubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  color: #ffffff;
  margin-bottom: 32px;
  a {
    text-decoration: underline;
  }
`

export const RulesTitle = styled.h1`
  font-family: 'Nizzoli';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  color: #ffffff;
  margin-bottom: 8px;
`

export const RulesTextAlignJustify = styled.p`
  text-align: justify;
`
