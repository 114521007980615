import { KycApi } from 'api/kyc.api'
import { KycError } from 'enum/KycError.enum'
import { KycStatus } from 'enum/KycStatus.enum'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { State } from 'reducers'
import { KycProcessView } from './KycProcess.view'

export const KycProcess = () => {
  const navigate = useNavigate()
  const investor = useSelector((state: State) => state.investor)
  const icoProgress = useSelector((state: State) => state.icoProgress)

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!investor || !investor?.investorId || !investor?.investorDataHash) {
        return
      }
      const kycRes = await KycApi.getStatus(investor.investorId, investor.investorDataHash)
      const kyc = kycRes.data.DATA.status

      switch (kyc) {
        case KycStatus.NO_ADDRESS:
          navigate('/informations', { state: { lastStep: true } })
          break
        case KycStatus.RETRY:
        case KycStatus.NOT_STARTED:
          navigate('/kyc-documents')
          break
        case KycStatus.REJECTED:
          navigate('/kyc-error', { state: { error: KycError.REJECTED } })
          break
        case KycStatus.CLEAR:
          if (icoProgress.funding === 0) {
            navigate('/kyc-finished')
          } else {
            navigate('/eligible')
          }
          break
        default:
          break
      }
    }, 30000)
    return () => clearInterval(interval)
  }, [icoProgress, investor, navigate])

  return <KycProcessView />
}
