import { SET_REFERRER } from "actions/referrer"

export interface ReferrerState {
    referrer?: string
}

const referrerDefaultState: ReferrerState = {
    referrer: undefined,
}

export function referrer(state = referrerDefaultState, action: any): ReferrerState {
  switch (action.type) {
    case SET_REFERRER:
      return {
        referrer: action.referrer,
      }
    default:
      return state
  }
}
