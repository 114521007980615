import { SET_INVESTOR_DATA } from 'actions/investor'

export interface InvestorState {
    investorId?: number
    investorDataHash?: string
}

const investorDefaultState: InvestorState = {
    investorId: undefined,
    investorDataHash: undefined,
}

export function investor(state = investorDefaultState, action: any): InvestorState {
  switch (action.type) {
    case SET_INVESTOR_DATA:
      return {
        investorId: action.investorId,
        investorDataHash: action.investorDataHash,
      }
    default:
      return state
  }
}
