import { KycDocumentsCard, KycDocumentsStyled } from './KycDocuments.style'
import { HomeButton } from 'app/components/HomeButton/HomeButton.view'

export const KycDocumentsView = () => {
  return (
    <KycDocumentsStyled>
      <HomeButton />
      <KycDocumentsCard>
        <div id="onfido-mount" />
      </KycDocumentsCard>
    </KycDocumentsStyled>
  )
}
