import { SET_ICO_PROGRESS_DATA } from './../actions/icoProgress'

export interface IcoProgressState {
  phase?: number
  funding?: number
  kyc?: number
}

const icoProgressDefaultState: IcoProgressState = {
  phase: 0,
  funding: 0,
  kyc: 0,
}

export function icoProgress(state = icoProgressDefaultState, action: any): IcoProgressState {
  switch (action.type) {
    case SET_ICO_PROGRESS_DATA:
      return {
        phase: action.phase,
        kyc: action.kyc,
        funding: action.funding,
      }
    default:
      return state
  }
}
