import { InputComponent, InputErrorMessage, InputIcon, InputStatus, InputStyled } from './Input.style'

interface InputViewProps {
  icon?: string
  placeholder: string
  name?: string
  value?: string | number
  onChange: (value: string | number) => void
  onPaste?: (e: any) => void
  onBlur: (value: string | number) => void
  inputStatus?: 'success' | 'error'
  type: string
  id?: string
  errorMessage?: string
  disabled?: boolean
}

export const InputView = ({
  icon,
  placeholder,
  name,
  value,
  onChange,
  onPaste,
  onBlur,
  inputStatus,
  type = 'text',
  id,
  errorMessage,
  disabled,
}: InputViewProps) => (
  <InputStyled>
    {icon && (
      <InputIcon>
        <use xlinkHref={`/icons/sprites.svg#${icon}`} />
      </InputIcon>
    )}
    <InputComponent
      type={type}
      id={id}
      name={name}
      className={inputStatus}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onPaste={onPaste}
      onBlur={(e) => onBlur(e.target.value)}
      autoComplete={name}
      disabled={disabled}
    />
    <InputStatus className={inputStatus} />
    {errorMessage && <InputErrorMessage>{errorMessage}</InputErrorMessage>}
  </InputStyled>
)
