import { Button } from 'app/components/Button/Button.controller'
import { HomeButton } from 'app/components/HomeButton/HomeButton.view'
import { Input } from 'app/components/Input/Input.controller'
import { InputErrorMessage } from 'app/components/Input/Input.style'
import { Formik, FormikProps } from 'formik'
import { IcoProgressState } from 'reducers/icoProgress'
import { Box, Card } from 'styles'
import * as yup from 'yup'

// prettier-ignore
import { FormAddressInputContainer, FormButtonGoBackIcon, FormContainer, FormContainerTitle, FormInfo, FormInputCheckbox, FormInputContainer, FormLabel, FormStyled, FormSubmit, FormSubTitle, FormText, FormTitle, FormWarning } from './Form.style'

interface FormViewProps {
  isLastStep: Boolean
  informationsSchema: yup.ObjectSchema<any>
  walletAddressSchema: yup.ObjectSchema<any>
  handleSubmitInformations: (values: any) => Promise<void>
  handleSubmitWalletAddress: (values: any) => Promise<void>
  walletInitialValues: { address: string | undefined; checkbox: boolean }
  informationsInitialValues: {
    firstname: string | undefined
    lastname: string | undefined
    email: string | undefined
    emailConfirmation: string | undefined
    referrer: string | undefined
    checkbox: boolean
  }
  icoProgress: IcoProgressState
  setIsLastStep: React.Dispatch<React.SetStateAction<boolean>>
}

export const FormView = ({
  isLastStep,
  informationsSchema,
  walletAddressSchema,
  handleSubmitInformations,
  handleSubmitWalletAddress,
  walletInitialValues,
  informationsInitialValues,
  icoProgress,
  setIsLastStep,
}: FormViewProps) => {
  return (
    <FormStyled>
      <HomeButton />
      <Box display="flex" alignItems="center" justifyContent="center">
        <Card>
          {!isLastStep ? (
            <Formik
              initialValues={informationsInitialValues}
              validationSchema={informationsSchema}
              onSubmit={handleSubmitInformations}
              enableReinitialize
            >
              {({
                setFieldValue,
                handleSubmit,
                handleBlur,
                errors,
                values,
              }: FormikProps<typeof informationsInitialValues>) => (
                <FormContainer onSubmit={handleSubmit}>
                  <FormTitle>Basic Information</FormTitle>
                  {icoProgress.kyc === 0 && icoProgress.funding === 1 && (
                    <Box marginTop={30} marginBottom={20}>
                      <FormInfo>
                        Please enter the <b>exact same information</b> as when you did during KYC
                      </FormInfo>
                    </Box>
                  )}
                  <FormInputContainer>
                    <FormLabel htmlFor="firstname">First Name</FormLabel>
                    <Input
                      name="firstname"
                      id="firstname"
                      type="text"
                      value={values.firstname}
                      onChange={(value) => setFieldValue('firstname', value, false)}
                      onBlur={handleBlur}
                      placeholder="Enter your first name"
                      errorMessage={errors.firstname}
                    />
                  </FormInputContainer>
                  <FormInputContainer>
                    <FormLabel htmlFor="lastname">Last Name</FormLabel>
                    <Input
                      id="lastname"
                      name="lastname"
                      type="text"
                      value={values.lastname}
                      onChange={(value) => setFieldValue('lastname', value, false)}
                      onBlur={handleBlur}
                      placeholder="Enter your last name"
                      errorMessage={errors.lastname}
                    />
                  </FormInputContainer>
                  <FormInputContainer>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={(value) => setFieldValue('email', value, false)}
                      onBlur={handleBlur}
                      placeholder="Enter your email address"
                      errorMessage={errors.email}
                    />
                  </FormInputContainer>
                  <FormInputContainer>
                    <FormLabel htmlFor="emailConfirmation">Email Confirmation</FormLabel>
                    <Input
                      id="emailConfirmation"
                      type="email"
                      name="emailConfirmation"
                      value={values.emailConfirmation}
                      onChange={(value) => setFieldValue('emailConfirmation', value, false)}
                      onPaste={(e: any) => {
                        e.preventDefault()
                        return false
                      }}
                      onBlur={handleBlur}
                      placeholder="Enter your email address again"
                      errorMessage={errors.emailConfirmation}
                    />
                  </FormInputContainer>
                  <Box marginTop={20} marginBottom={20}>
                    <FormWarning>
                      <label htmlFor={'checkbox'}>
                        <FormInputCheckbox
                          type="checkbox"
                          id={'checkbox'}
                          name={'checkbox'}
                          onChange={(event) => setFieldValue('checkbox', event.target.checked, true)}
                        />
                        {icoProgress.kyc === 0 && icoProgress.funding === 1 ? (
                          <span>I confirm I entered the exact same information as in the KYC Registration process</span>
                        ) : (
                          <span>
                            I confirm my information are correctly typed in, and that I noted it well to access the ICO.
                          </span>
                        )}
                      </label>
                      {Boolean(errors.checkbox) && <InputErrorMessage>{errors.checkbox}</InputErrorMessage>}
                    </FormWarning>
                  </Box>

                  <FormInfo>
                    If you want to participate in the ICO as a company, <br />
                    please reach&nbsp;
                    <a href="mailto:kyb@cardashift.com">kyb@cardashift.com</a>
                  </FormInfo>
                  <Box display="flex" justifyContent="center" alignItems="center" marginTop={40}>
                    <FormSubmit>
                      <Button type="submit" text="Next" icon="arrow" large />
                    </FormSubmit>
                  </Box>
                </FormContainer>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={walletInitialValues}
              validationSchema={walletAddressSchema}
              onSubmit={handleSubmitWalletAddress}
              enableReinitialize
            >
              {({
                setFieldValue,
                handleSubmit,
                handleBlur,
                errors,
                values,
              }: FormikProps<typeof walletInitialValues>) => (
                <FormContainer onSubmit={handleSubmit}>
                  <FormContainerTitle marginBottom={31}>
                    <FormButtonGoBackIcon onClick={() => setIsLastStep(false)}>
                      <use xlinkHref={`/icons/sprites.svg#arrow`} />
                    </FormButtonGoBackIcon>
                    <FormTitle>Wallet Address</FormTitle>
                    <div></div>
                  </FormContainerTitle>
                  <FormSubTitle>Enter your wallet address to receive your CLAP after the ICO</FormSubTitle>
                  <FormText>
                    Please make sure this wallet is compatible with Cardano Native Tokens{' '}
                    <a
                      href="https://cardashift.medium.com/why-and-how-to-do-our-kyc-e58c10ef01cf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      (read our tutorial)
                    </a>
                  </FormText>
                  <FormAddressInputContainer>
                    <Input
                      name="walletAddress"
                      type="text"
                      id="walletAddress"
                      value={values.address}
                      onChange={(value) => setFieldValue('address', value, false)}
                      onBlur={handleBlur}
                      placeholder="Enter your address"
                      errorMessage={errors.address}
                    />
                    {Boolean(errors.address) && (
                      <InputErrorMessage>
                        Please enter your Cardano Wallet address or create one in two minutes (follow our tutorial{' '}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            'https://cardashift.gitbook.io/cardashift/tutorials/how-to-do-our-kyc/how-to-create-a-cardano-wallet-address-for-the-kyc'
                          }
                        >
                          <b>here</b>
                        </a>
                        )
                      </InputErrorMessage>
                    )}
                  </FormAddressInputContainer>
                  <FormInputContainer>
                    <FormWarning>
                      <label htmlFor={'checkbox'}>
                        <FormInputCheckbox
                          type="checkbox"
                          id={'checkbox'}
                          name={'checkbox'}
                          onChange={(event) => setFieldValue('checkbox', event.target.checked, false)}
                        />
                        <span>
                          I understand that I will not be allowed to change this address anymore once I click on next.
                        </span>
                      </label>
                      {Boolean(errors.checkbox) && <InputErrorMessage>{errors.checkbox}</InputErrorMessage>}
                    </FormWarning>
                  </FormInputContainer>

                  <Box marginTop={30}>
                    <FormSubmit>
                      <Button type="submit" text="Next" icon="arrow" large />
                    </FormSubmit>
                  </Box>
                </FormContainer>
              )}
            </Formik>
          )}
        </Card>
      </Box>
    </FormStyled>
  )
}
