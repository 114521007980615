import styled from 'styled-components/macro'
import { Page } from 'styles'

export const KycDocumentsStyled = styled(Page)`
  width: 100%;
  background-image: url('/images/bg-shapes.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;

  @media (max-width: 800px) {
    padding: 0px;
  }
`

export const KycDocumentsCard = styled.div`
  width: 600px;
  margin: 0 auto;
`
