import { InputView } from './Input.view'

interface InputProps {
  icon?: string
  placeholder: string
  name?: string
  value?: string | number
  onChange: (value: string | number) => void
  onPaste?: (e: any) => void
  onBlur: (value: string | number) => void
  inputStatus?: 'success' | 'error'
  id?: string
  type: string
  errorMessage?: string
  disabled?: boolean
}

export const Input = ({
  icon,
  placeholder,
  name,
  value,
  onChange,
  onPaste,
  onBlur,
  inputStatus,
  id,
  type = 'text',
  errorMessage,
  disabled,
}: InputProps) => (
  <InputView
    type={type}
    id={id}
    icon={icon}
    name={name}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    onPaste={onPaste}
    onBlur={onBlur}
    inputStatus={inputStatus}
    errorMessage={errorMessage}
    disabled={disabled}
  />
)
