import { Button } from 'app/components/Button/Button.controller'
import { HomeButton } from 'app/components/HomeButton/HomeButton.view'
import { useNavigate } from 'react-router'
import { Box } from 'styles'

// prettier-ignore
import { RulesCard, RulesRuleBlock, RulesStyled, RulesSubTitle, RulesTextAlignJustify, RulesTitle } from './Rules.style'

export const RulesView = () => {
  const navigate = useNavigate()

  return (
    <RulesStyled>
      <HomeButton />
      <RulesCard>
        <RulesTitle>ICO's rules</RulesTitle>
        <RulesRuleBlock>
          <RulesSubTitle>
            Please read our T&C here :{' '}
            <a href="https://cardashift.com/terms.pdf" target="_blank" rel="noreferrer">
              https://cardashift.com/terms.pdf
            </a>
          </RulesSubTitle>
          <RulesTextAlignJustify>
            These Terms form the basis for the CLAP Tokens distribution and are part of the Cardashift White Paper. They
            prevail over all other documents issued by Smart Impact and, as from their date of entry into force, shall
            apply to all purchases, including outstanding orders, and are deemed to be unconditionally accepted by the
            Purchasers as of the conclusion of an order. Any other document (e.g. marketing materials, presentation
            under any format if any, etc.) is, therefore, given for information purpose only and shall not constitute a
            contract document that commits the liability of Smart Impact, which may therefore withdraw or modify such
            documents, without entitling the Purchaser to any compensation. By adhering to the Terms, the Purchaser
            expressly acknowledges having been thoroughly and comprehensively informed about the contributing process
            for the Project. <br /> <br />
            The Purchaser is deemed to be fully aware of all the legal norms and technical constraints relating to the
            acquiring, purchase, possession and use of crypto currencies and tokens based on Blockchain technologies,
            and to the services offered by Smart Impact.
          </RulesTextAlignJustify>
        </RulesRuleBlock>
        <Box display="flex" justifyContent="center" alignItems="center" marginTop={40}>
          <Button text="I agree to terms and conditions" icon="arrow" onClick={() => navigate('/informations')} large />
        </Box>
      </RulesCard>
    </RulesStyled>
  )
}
