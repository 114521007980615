import styled from 'styled-components/macro'

export const FooterContainer = styled.div`
  background-color: rgb(12, 26, 108);
  color: rgb(255, 255, 255);
  padding: 50px 10%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  @media (max-width: 900px) {
    display: block;
    a {
      margin-right: 10px;
    }
    a:last-child {
      margin-right: 0px;
    }
  }
`
export const FooterElement = styled.div`
  flex: 1 1 calc(33.3333%);
  :nth-child(2) {
    text-align: center;
    line-height: 22px;
  }
  :nth-child(3) {
    display: flex;
    justify-content: right;
  
  }
  :nth-child(3) a {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(36, 49, 123);
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-right: 10px;
  }
  :nth-child(3) a:last-child {
    margin-right: 0px;
  }
  > a {
    color: rgb(255, 255, 255);
    text-decoration: underline;
  }
  @media (max-width: 900px){
    :nth-child(2), :nth-child(3) {
      margin-top: 20px;
      text-align: left;
    }
    :nth-child(3) {
      justify-content: left;
    }
  }
}
`
