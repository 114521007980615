import styled from 'styled-components/macro'
import { Card, Page } from 'styles'

export const KycProcessCard = styled(Card)`
  position: relative;
`
export const KycProcessStyled = styled(Page)`
  width: 100%;
  background-image: url('/images/bg-shapes.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
`

export const KycProcessTitle = styled.h1`
  font-family: 'Nizzoli';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  padding: 0px 110px;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    padding: 0;
  }
`

export const KycProcessTxt = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
`
export const KycProcessLink = styled.a`
  font-weight: bold;
`
