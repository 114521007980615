import { KycDocumentsView } from './KycDocuments.view'
import { showToaster, ToasterStatus } from 'app/components/Toaster/Toaster.actions'
import { KycError } from 'enum/KycError.enum'
import { init, SdkHandle } from 'onfido-sdk-ui'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { State } from 'reducers'
import { AxiosResponse } from 'axios'
import { ApiResponse } from 'models/ApiResponse.model'
import { ApiStatus } from 'enum/ApiStatus.enum'
import { KycApi } from 'api/kyc.api'

export const KycDocuments = () => {
  const [onfidoInstance, setOnfidoInstance] = useState<SdkHandle>()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const investor = useSelector((state: State) => state.investor)

  const createCheck = useCallback(async () => {
    try {
      if (!investor || !investor?.investorId || !investor?.investorDataHash) {
        return
      }
      const response = await KycApi.createCheck({
        investor_id: investor.investorId,
        investor_data_hash: investor.investorDataHash,
      })
      if (response.data.STATUS === ApiStatus.SUCCESS) {
        navigate('/kyc-process', { replace: true })
      } else if (
        response.data.STATUS === ApiStatus.FAILURE &&
        response.data.MESSAGE === 'max number of kyc trials reached'
      ) {
        navigate('/kyc-error', { state: { error: KycError.MAX_ATTEMPT }, replace: true })
      } else {
        navigate('/informations', { replace: true })
      }
    } catch (err) {
      navigate('/informations', { replace: true })
    }
  }, [investor, navigate])

  const initOnFido = useCallback(async () => {
    try {
      if (!investor || !investor?.investorId || !investor?.investorDataHash) {
        return
      }
      const tokenResponse: AxiosResponse<ApiResponse<string>> = await KycApi.getToken(
        investor.investorId,
        investor.investorDataHash,
      )

      if (tokenResponse.data.STATUS !== 'SUCCESS') {
        throw new Error()
      }

      const token = tokenResponse.data.DATA
      const instance = init({
        useModal: false,
        token,
        onComplete: () => createCheck(),
        steps: ['document', 'face', 'complete'],
        language: {
          locale: 'en_US',
          phrases: {},
        },
      })

      setOnfidoInstance(instance)
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          showToaster(ToasterStatus.Error, 'Error', "Couldn't initialize the KYC, please contact the Cardashift Team"),
        )
        return
      }
    }
  }, [createCheck, dispatch, investor])

  useEffect(() => {
    initOnFido()

    return () => {
      if (onfidoInstance) {
        onfidoInstance.tearDown()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <KycDocumentsView />
}
