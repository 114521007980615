import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Fragment } from 'react'
import ReactDOM from 'react-dom'

import { App } from './app/App'
import reportWebVitals from './reportWebVitals'
import { unregister } from './serviceWorker'
import { GlobalStyle } from './styles'

import './styles/fonts.css'

Sentry.init({
  dsn: 'https://76c07d7bba31482bb39dc53bb3242d1f@o1121970.ingest.sentry.io/6159125',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

export const Root = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <App />
    </Fragment>
  )
}

const rootElement = document.getElementById('root')
ReactDOM.render(<Root />, rootElement)

unregister()
reportWebVitals()
