import { useState } from 'react'
import { BUTTON, ButtonStyle, ButtonTypes, PRIMARY } from './Button.constants'
import { ButtonView } from './Button.view'

interface ButtonProps {
  text: string
  icon?: string
  kind?: ButtonStyle
  onClick?: () => void
  type?: ButtonTypes
  loading?: boolean
  large?: boolean
  floating?: boolean
}

export const Button = ({
  text,
  icon,
  kind = PRIMARY,
  onClick,
  type = BUTTON,
  loading = false,
  large = false,
  floating = false,
}: ButtonProps) => {
  const [clicked, setClicked] = useState(false)
  const clickCallback = () => {
    setClicked(true)
    setTimeout(() => setClicked(false), 1000)
  }
  return (
    <ButtonView
      text={text}
      icon={icon}
      kind={kind}
      onClick={onClick}
      clicked={clicked}
      clickCallback={clickCallback}
      type={type}
      loading={loading}
      large={large}
      floating={floating}
    />
  )
}
