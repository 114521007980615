import { InvestorApi } from 'api/investor.api'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { State } from 'reducers'
import { EligibleView } from './Eligible.view'

export const Eligible = () => {
  const [address, setAddress] = useState<string>('')
  const [amountSent, setAmountSent] = useState<number>(0)
  const [max, setMax] = useState<number>(0)
  const [min, setMin] = useState<number>(0)
  const investor = useSelector((state: State) => state.investor)

  const getInfos = useCallback(async () => {
    try {
      const res = await InvestorApi.getInvestorProgress(investor)

      if (res.data.STATUS !== 'SUCCESS') {
        throw Error()
      }

      if (res.data.DATA.amount_sent !== null) {
        setAmountSent(res.data.DATA.amount_sent)
      }
      setAddress(res.data.DATA.generated_address)
      setMax(res.data.DATA.investor_max_cap)
      setMin(res.data.DATA.investor_min_cap)
    } catch (err) {}
  }, [investor])

  useEffect(() => {
    getInfos()
    const interval = setInterval(() => getInfos(), 30000)
    return () => clearInterval(interval)
  }, [getInfos])

  return <EligibleView address={address} amountSent={amountSent} max={max} min={min} />
}
