import { setReferrer } from 'actions/referrer'
import { IcoApi } from 'api/ico.api'
import { ReferralApi } from 'api/referral.api'
import { ApiStatus } from 'enum/ApiStatus.enum'
import { Phase } from 'enum/Phase.enum'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { State } from 'reducers'

import { LandingView } from './Landing.view'

export const Landing = () => {
  const [progress, setProgress] = useState(0)
  const [max, setMax] = useState(0)
  const dispatch = useDispatch()
  const { referrer } = useParams()

  const icoProgress = useSelector((state: State) => state.icoProgress)

  const getProgressBar = useCallback(async () => {
    try {
      const res = await IcoApi.getProgress()
      if (res.data.STATUS !== ApiStatus.SUCCESS) {
        throw Error()
      }

      setProgress(res.data.DATA.amount)
      setMax(res.data.DATA.ico_cap)
    } catch (err) {}
  }, [])

  const postClick = useCallback(
    async (referrer: string) => {
      dispatch(setReferrer(referrer))

      try {
        await ReferralApi.click(referrer)
      } catch (err) {}
    },
    [dispatch],
  )

  useEffect(() => {
    if (
      icoProgress.phase === Phase.FLASH_SALE ||
      icoProgress.phase === Phase.PUBLIC_SALE ||
      icoProgress.phase === Phase.CLOSED
    ) {
      getProgressBar()
    }
    if (referrer) postClick(referrer)
  }, [icoProgress, referrer, getProgressBar, postClick])

  const saleProgress = useMemo(() => (progress === 0 ? 0 : (progress * 100) / max), [progress, max])

  return <LandingView saleProgress={saleProgress} icoProgress={icoProgress} max={max} />
}
