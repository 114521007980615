import { BUTTON, ButtonStyle, ButtonTypes, PRIMARY } from './Button.constants'
import { ButtonIcon, ButtonLoadingIcon, ButtonStyled, ButtonText } from './Button.style'
import clsx from 'clsx'

interface ButtonViewProps {
  text: string
  icon?: string
  kind?: ButtonStyle
  onClick?: () => void
  clickCallback: () => void
  clicked: boolean
  type?: ButtonTypes
  loading: boolean
  large?: boolean
  floating?: boolean
}

export const ButtonView = ({
  text,
  icon,
  kind = PRIMARY,
  onClick = () => {},
  clickCallback,
  clicked,
  type = BUTTON,
  loading = false,
  large = false,
  floating = false,
}: ButtonViewProps) => (
  <ButtonStyled
    type={type}
    className={clsx(kind, { clicked, loading, large, floating })}
    onClick={() => {
      clickCallback()
      onClick()
    }}
  >
    <ButtonText>
      {loading ? (
        <>
          <div>Loading...</div>
          <ButtonLoadingIcon className={kind}>
            <use xlinkHref="/icons/sprites.svg#loading" />
          </ButtonLoadingIcon>
        </>
      ) : (
        <>
          <div>{text}</div>
          {icon && (
            <ButtonIcon className={kind}>
              <use xlinkHref={`/icons/sprites.svg#${icon}`} />
            </ButtonIcon>
          )}
        </>
      )}
    </ButtonText>
  </ButtonStyled>
)
