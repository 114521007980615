import Axios from 'axios';
import { ApiResponse } from 'models/ApiResponse.model';
import { Investor } from 'models/Investor.model';
import { InvestorProgress } from 'models/InvestorProgress.model';
import { InvestorState } from 'reducers/investor';

export namespace InvestorApi {

  export type InvestorIdentificationRequest = {
    firstname: string;
    lastname: string;
    email: string;
    referrer: string | undefined
  }

  export type UpdateAddressRequest = {
    address: string,
    investor_id: number,
    investor_data_hash: string,
  }

  export const getInvestorProgress = (investor: InvestorState) => {
    return Axios.get<ApiResponse<InvestorProgress>>(
      `/investor/personal-ico-details/${investor.investorId}/${investor.investorDataHash}`,
    )
  }

  export const identification = (data : InvestorIdentificationRequest) => {
    return Axios.post<ApiResponse<Investor>>('/investor/identification', data)
  }

  export const updateAddress = (data: UpdateAddressRequest) => {
    return Axios.post<ApiResponse<null>>('/investor/address-form', data)
  }
}