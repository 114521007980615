import { Button } from 'app/components/Button/Button.controller'
import { HomeButton } from 'app/components/HomeButton/HomeButton.view'
import { Link } from 'react-router-dom'
import { Card, progressColor, thirdColor } from 'styles'
import { numberWithCommas } from 'utils/utils'

//prettier-ignore
import { EligibleAdaLostText, EligibleAdaSentBackText, EligibleAddressBlock, EligibleAddressText, EligibleBoldText, EligibleCopyIcon, EligibleProgress, EligibleProgressBar, EligibleProgressContainer, EligibleProgressRowText, EligibleProgressText, EligibleProgressTooltipTriangle, EligibleStyled, EligibleText, EligibleTextParticipation, EligibleTextStart, EligibleTitle, EligibleTooltipBox, EligibleTooltipContainer, WarningText } from './Eligible.style'

interface EligibleViewProps {
  address: string
  amountSent: number
  max: number
  min: number
}

export const EligibleView = ({ address, amountSent, max, min }: EligibleViewProps) => {
  return (
    <EligibleStyled>
      <HomeButton />
      <Card>
        <EligibleTitle>You are eligible for the ICO.</EligibleTitle>
        <EligibleText>
          You can send your ADA to the following address from wherever you want (Exchange, Wallet, etc.):
        </EligibleText>
        <EligibleAddressBlock>
          <EligibleAddressText>{address}</EligibleAddressText>
          <EligibleCopyIcon onClick={() => navigator.clipboard.writeText(address)}>
            <use xlinkHref={`/icons/sprites.svg#copy`} />
          </EligibleCopyIcon>
        </EligibleAddressBlock>
        <EligibleTextStart>
          A minimum of <b>{numberWithCommas(min)} ADA</b> is required to participate in the ICO. <br />
          Any funds sent below this amount <b>won't be refunded (pay attention to transaction fees)</b>
        </EligibleTextStart>
        <EligibleTextStart>
          The maximum cap per investor is <EligibleBoldText>{numberWithCommas(max)} ADA</EligibleBoldText>.
          <br /> Any excess will be given back minus transaction fees.
        </EligibleTextStart>
        <EligibleTextParticipation>My participation:</EligibleTextParticipation>
        <EligibleProgressBlock
          progress={amountSent === 0 ? 0 : (amountSent * 100) / max}
          adaReceived={amountSent}
          adaSentBack={amountSent - max}
          maxAda={max}
          minAda={min}
        />
        <EligibleText>
          <i>
            This progress bar will automatically update once we receive your funds. <br />
            Transactions can take up to 20 minutes to be confirmed
          </i>
        </EligibleText>
        <WarningText>
          If you need help, check our{' '}
          <a
            href="https://cardashift.gitbook.io/cardashift/tutorials/how-to-participate-in-cardashifts-ico"
            target="_blank"
            rel="noreferrer"
          >
            tutorial
          </a>{' '}
          here or reach us at <a href="mailto:ico@cardashift.com">ico@cardashift.com</a>.
        </WarningText>

        <Link to="/">
          <Button text="Close Session" />
        </Link>
      </Card>
    </EligibleStyled>
  )
}

/**
 * Component with the progress logic
 * @param progress Progress of the progress bar in % sent in number
 * @param maxAda Max Ada investable
 * @param adaReceived Ada received from the investor
 * @param adaSentBack Ada sent back to the investor
 * @returns
 */
const EligibleProgressBlock = ({
  progress,
  maxAda,
  adaReceived,
  adaSentBack,
  minAda,
}: {
  progress: number
  maxAda: number
  adaReceived: number
  adaSentBack: number
  minAda: number
}) => {
  return (
    <EligibleProgressContainer>
      <EligibleProgressRowText>
        <EligibleProgressText color={minAda > adaReceived ? thirdColor : 'white'}>
          <EligibleBoldText>Min {numberWithCommas(minAda)} ADA</EligibleBoldText>
        </EligibleProgressText>
        <EligibleProgressText color={progress > 100 ? thirdColor : 'white'}>
          <EligibleBoldText>Max {numberWithCommas(maxAda)} ADA</EligibleBoldText>
        </EligibleProgressText>
      </EligibleProgressRowText>
      <EligibleProgressBar>
        <EligibleProgress
          width={progress > 100 ? 100 : progress}
          color={progress > 100 || minAda > adaReceived ? thirdColor : progressColor}
        />
      </EligibleProgressBar>
      <EligibleTooltipContainer left={progress > 100 ? 100 : progress}>
        <EligibleTooltipBox>
          <EligibleProgressTooltipTriangle src="/images/triangle.png" alt="triangle" />
          <EligibleProgressText>
            <EligibleBoldText>{numberWithCommas(adaReceived)} ADA</EligibleBoldText>
          </EligibleProgressText>
        </EligibleTooltipBox>
      </EligibleTooltipContainer>
      {progress > 100 && (
        <EligibleAdaSentBackText color={thirdColor}>
          ⚠️ &nbsp;&nbsp; {numberWithCommas(adaSentBack)} ADA will be returned
        </EligibleAdaSentBackText>
      )}
      {adaReceived !== 0 && minAda > adaReceived && (
        <EligibleAdaLostText color={thirdColor}>
          ⚠️ &nbsp;&nbsp;A minimum of {numberWithCommas(minAda)} ADA is required !
        </EligibleAdaLostText>
      )}
    </EligibleProgressContainer>
  )
}
