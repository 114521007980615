import { setAddress } from 'actions/address'
import { setFormData } from 'actions/form'
import { setInvestorData } from 'actions/investor'
import { InvestorApi } from 'api/investor.api'
import { KycError } from 'enum/KycError.enum'
import { KycStatus } from 'enum/KycStatus.enum'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { State } from 'reducers'
import * as yup from 'yup'

import { FormView } from './Form.view'

const WalletValidator = require('multicoin-address-validator')
const getSlug = require('speakingurl')

const isValidName = (value: string | undefined) => {
  if (!value || getSlug(value, { separator: '' }) === '') {
    return false
  } else {
    return !RegexLetters.test(value)
  }
}

const RegexLetters = /[^A-zÀ-ÿ\s-]/g
const informationsSchema = yup.object().shape({
  firstname: yup
    .string()
    .required('First name is a required field')
    .test('testFirstname', 'First name can only contain letters without special characters', (value) =>
      isValidName(value),
    )
    .min(2, 'First name must be at least 2 characters'),
  lastname: yup
    .string()
    .required('Last Name is a required field')
    .test('testLastname', 'Last Name can only contain letters without special characters', (value) =>
      isValidName(value),
    )
    .min(2, 'Last Name must be at least 2 characters'),
  email: yup.string().email().required(),
  emailConfirmation: yup
    .string()
    .email()
    .required('Please confirm your email')
    .oneOf([yup.ref('email'), null], 'Emails must match'),
  referrer: yup.string().optional(),
  checkbox: yup.boolean().oneOf([true], 'Check the box to proceed.'),
})

const walletAddressSchema = yup.object().shape({
  address: yup.string().test('isCardanoAdress', 'Invalid compatible wallet address. ', (value) => {
    if (!value) {
      return false
    } else {
      return WalletValidator.validate(value, 'ada', 'prod')
    }
  }),
  checkbox: yup.boolean().oneOf([true], 'Check the box to proceed.'),
})

const walletInitialValues = {
  address: '',
  checkbox: false,
}

export const Form = () => {
  const location = useLocation()
  // @ts-ignore
  const isLastStepDefaultValue = location && location.state && location.state.lastStep ? true : false
  const [isLastStep, setIsLastStep] = useState(isLastStepDefaultValue)
  const referrer = useSelector((state: State) => state.referrer.referrer)
  const formData = useSelector((state: State) => state.formData)

  const informationsInitialValues = useMemo(() => {
    return {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      emailConfirmation: formData.emailConfirmation,
      referrer: referrer,
      checkbox: false,
    }
  }, [referrer, formData])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const investor = useSelector((state: State) => state.investor)
  const icoProgress = useSelector((state: State) => state.icoProgress)

  const handleSubmitInformations = useCallback(
    async (values) => {
      try {
        const response = await InvestorApi.identification({
          firstname: getSlug(values.firstname.toLowerCase(), {
            separator: '',
          }).replaceAll('-', ''),
          lastname: getSlug(values.lastname.toLowerCase(), {
            separator: '',
          }).replaceAll('-', ''),
          email: values.email,
          referrer: values.referrer,
        })
        if (response.data.STATUS !== 'SUCCESS') {
          throw new Error()
        }

        dispatch(setInvestorData(response.data.DATA.investor_id, response.data.DATA.investor_data_hash))
        dispatch(setFormData(values.firstname, values.lastname, values.email, values.emailConfirmation))
        const kycStatus = response.data.DATA.status
        if (icoProgress.kyc === 1) {
          switch (kycStatus) {
            case KycStatus.NO_ADDRESS:
              setIsLastStep(true)
              break
            case KycStatus.RETRY:
            case KycStatus.NOT_STARTED:
              navigate('/kyc-documents')
              break
            case KycStatus.PENDING:
              navigate('/kyc-process')
              break
            case KycStatus.REJECTED:
              navigate('/kyc-error', { state: { error: KycError.REJECTED } })
              break
            case KycStatus.CLEAR:
              dispatch(setAddress(response.data.DATA.investor_address))
              if (icoProgress.funding === 0) {
                navigate('/kyc-finished')
              } else {
                navigate('/eligible')
              }
              break
            default:
              break
          }
        } else {
          if (kycStatus === KycStatus.CLEAR) {
            navigate('/eligible')
          } else {
            navigate('/kyc-error', { state: { error: KycError.KYC_CLOSED } })
          }
        }
      } catch (e: any) {}
    },
    [icoProgress, dispatch, navigate],
  )

  const handleSubmitWalletAddress = useCallback(
    async (values) => {
      try {
        if (!investor || !investor?.investorId || !investor?.investorDataHash) {
          return
        }
        const response = await InvestorApi.updateAddress({
          address: values.address,
          investor_id: investor.investorId,
          investor_data_hash: investor.investorDataHash,
        })
        dispatch(setAddress(values.address))
        if (response.data.STATUS !== 'SUCCESS') {
          throw new Error()
        }
        navigate('/kyc-documents')
      } catch (error: any) {}
    },
    [investor, navigate, dispatch],
  )

  return (
    <FormView
      isLastStep={isLastStep}
      informationsSchema={informationsSchema}
      informationsInitialValues={informationsInitialValues}
      walletInitialValues={walletInitialValues}
      walletAddressSchema={walletAddressSchema}
      handleSubmitInformations={handleSubmitInformations}
      handleSubmitWalletAddress={handleSubmitWalletAddress}
      icoProgress={icoProgress}
      setIsLastStep={setIsLastStep}
    />
  )
}
