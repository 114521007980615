import { TimerGrid, TimerInner, TimerStyled } from './Timer.style'

interface TimerViewProps {
  seconds: number
  minutes: number
  hours: number
  days: number
  text?: string
}

export const TimerView = ({ text, seconds, minutes, hours, days }: TimerViewProps) => (
  <TimerStyled>
    <TimerInner>
      <div>{text || 'Starting in'}</div>
      <TimerGrid>
        <div>
          {days}
          <p>days</p>
        </div>
        <div>:</div>
        <div>
          {hours}
          <p>hours</p>
        </div>
        <div>:</div>
        <div>
          {minutes}
          <p>minutes</p>
        </div>
        <div>:</div>
        <div>
          {seconds}
          <p>seconds</p>
        </div>
      </TimerGrid>
    </TimerInner>
  </TimerStyled>
)
