import { HomeButton } from 'app/components/HomeButton/HomeButton.view'
import { KycError } from 'enum/KycError.enum'

import { KycErrorCard, KycErrorLink, KycErrorStyled, KycErrorTitle, KycErrorTxt } from './KycError.style'

interface KycErrorProps {
  error: KycError
}

export const KycErrorView = ({ error }: KycErrorProps) => {
  return (
    <KycErrorStyled>
      <HomeButton />
      <KycErrorCard>
        <div>
          <img src="/images/kyc-process-invalid.png" alt="" />
        </div>
        {error === KycError.KYC_CLOSED && (
          <KycErrorTitle>
            We cannot find any valid KYC that matches this information.
            <br />
            <br />
            The KYC registrations are now closed.
            <br /> You cannot participate
            <br />
            in the ICO.
          </KycErrorTitle>
        )}
        {error === KycError.MAX_ATTEMPT && (
          <>
            <KycErrorTitle>You have reached the maximum number of KYC attempts.</KycErrorTitle>
            <KycErrorTxt>
              If you think this is an error, please get in touch with <br />
              <KycErrorLink href="mailto:kyc@cardashift.com">kyc@cardashift.com</KycErrorLink>
            </KycErrorTxt>
          </>
        )}
        {error === KycError.REJECTED && (
          <>
            <KycErrorTitle>Your KYC is invalid</KycErrorTitle>
            <KycErrorTxt>
              Please check your email, you should have received an email with more details and an email address to reach
              if necessary.
            </KycErrorTxt>
          </>
        )}
      </KycErrorCard>
    </KycErrorStyled>
  )
}
