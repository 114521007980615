import { setIcoProgress } from 'actions/icoProgress'
import { IcoApi } from 'api/ico.api'
import { Affiliate } from 'pages/Affiliate/Affiliate.controller'
import { Eligible } from 'pages/Eligible/Eligible.controller'
import { FlashResolve } from 'pages/FlashResolve/FlashResolve.controller'
import { Form } from 'pages/Form/Form.controller'
import { KycDocuments } from 'pages/KycDocuments/KycDocuments.controller'
import { KycError } from 'pages/KycError/KycError.controller'
import { KycFinished } from 'pages/KycFinished/KycFinished.controller'
import { KycProcess } from 'pages/KycProcess/KycProcess.controller'
import { Footer } from 'pages/Landing/Footer/Footer.controller'
import { Landing } from 'pages/Landing/Landing.controller'
import { Rules } from 'pages/Rules/Rules.controller'
import { useCallback, useEffect, useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ReactGA from 'react-ga4'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { Location, Navigate, useLocation, useNavigate } from 'react-router'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { State } from 'reducers'
import { catchError } from 'services/catchError'

import { configureStore } from './App.store'
import { AppContainerStyled } from './App.style'
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback.view'
import { Toaster } from './components/Toaster/Toaster.controller'

import 'utils/axios'
import { FlashList } from 'pages/FlashList/FlashList.controller'

ReactGA.initialize('G-QYNTNPY527')
ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })

export const store = configureStore({})

const ProtectedComponent = ({ component }: { component: JSX.Element }) => {
  const location = useLocation()
  const investor = useSelector((state: State) => state.investor)
  const hasInvestorData = investor.investorId !== undefined && investor.investorDataHash !== undefined

  if (!hasInvestorData) {
    return <Navigate to="/informations" state={{ from: location }} replace />
  }

  return component
}

export const App = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={catchError}>
    <Provider store={store}>
      <BrowserRouter>
        <AppContainer>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Landing />
                  <Footer />
                </>
              }
            />
            {/* Example lien affilié: https://ico.cardashift.com/hasheur */}
            <Route path="/:referrer" element={<Landing />} />
            {/* Example panel affilié: https://ico.cardashift.com/affiliate/hasheur */}
            <Route path="/affiliate/:referrer" element={<Affiliate />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/informations" element={<Form />} />
            <Route path="/kyc-documents" element={<ProtectedComponent component={<KycDocuments />} />} />
            <Route path="/kyc-process" element={<ProtectedComponent component={<KycProcess />} />} />
            <Route path="/eligible" element={<ProtectedComponent component={<Eligible />} />} />
            <Route path="/kyc-finished" element={<ProtectedComponent component={<KycFinished />} />} />
            <Route path="/kyc-error" element={<ProtectedComponent component={<KycError />} />} />
            <Route path="/flashsale-resolve" element={<FlashResolve />} />
            <Route path="/flashsale-list" element={<FlashList />} />

            {/* For testing */}
            {/* <Route path="/affiliate/:referrer" element={<Affiliate />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/informations" element={<Form />} />
            <Route path="/kyc-documents" element={<KycDocuments />} />
            <Route path="/kyc-process" element={<KycProcess />} />
            <Route path="/eligible" element={<Eligible />} />
            <Route path="/kyc-finished" element={<KycFinished />} />
            <Route path="/kyc-error" element={<KycError />} /> */}
          </Routes>
        </AppContainer>
        <Toaster />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
)

const AppContainer: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const getIcoProgress = useCallback(async () => {
    try {
      const res = await IcoApi.getParams()
      dispatch(setIcoProgress(res.data.DATA.phase, res.data.DATA.kyc, res.data.DATA.funding))
    } catch (err) {}
  }, [dispatch])

  const usePrevious = (value: Location) => {
    const ref = useRef<Location>()
    useEffect(() => {
      ref.current = value
    })

    return ref.current
  }

  const useLocationChange = (action: (arg0: Location, arg1: Location | undefined) => void) => {
    const location = useLocation()
    const prevLocation = usePrevious(location)
    useEffect(() => {
      action(location, prevLocation)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
  }

  useLocationChange((location, prevLocation) => {
    if (prevLocation?.pathname === '/kyc-process' && location.pathname === '/kyc-documents') {
      navigate('/informations', { replace: true })
    }
  })

  useEffect(() => {
    getIcoProgress()
  }, [getIcoProgress])
  return <AppContainerStyled>{children}</AppContainerStyled>
}
