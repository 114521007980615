import { HomeButton } from 'app/components/HomeButton/HomeButton.view'
import { Referrer } from 'models/Referrer.model'
import { Card } from 'styles'
import { numberWithCommas } from 'utils/utils'

import { AffiliateGrid, AffiliateStyled } from './Affiliate.style'

interface AffiliateViewProps {
  refData: Referrer
  referrer: string | undefined
}

export const AffiliateView = ({ refData, referrer }: AffiliateViewProps) => {
  return (
    <AffiliateStyled>
      <HomeButton />
      <h1>{`Affiliate stats for ${referrer}`}</h1>
      <h2>
        Your affiliate link : <b>{`https://ico.cardashift.com/${referrer}`}</b>
      </h2>
      <h2>
        Your affiliate panel : <b>{`https://ico.cardashift.com/affiliate/${referrer}`}</b>
      </h2>
      <AffiliateGrid>
        <Card widthOverride="100%">
          <h2>Clicks</h2>
          <h1>{refData.clicks}</h1>
        </Card>
        <Card widthOverride="100%">
          <h2>Registered Referrals</h2>
          <h1>{refData.referees}</h1>
        </Card>
        <Card widthOverride="100%">
          <h2>Flash Sale</h2>
          <h1>{refData.amount_flash !== undefined && <span>{numberWithCommas(refData.amount_flash)}</span>} ADA</h1>
        </Card>
        <Card widthOverride="100%">
          <h2>Public Sale</h2>
          <h1>{refData.amount_public !== undefined && <span>{numberWithCommas(refData.amount_public)}</span>} ADA</h1>
        </Card>
      </AffiliateGrid>
    </AffiliateStyled>
  )
}
