import Axios from 'axios';
import { ApiResponse } from 'models/ApiResponse.model';
import { Referrer } from 'models/Referrer.model';

export namespace ReferralApi {
  export const getReferrer = (referrer: string | undefined) => {
    return Axios.get<ApiResponse<Referrer>>(`/referrals/${referrer}`);
  }

  export const click = (referrer: string) => {
    return Axios.post<ApiResponse<null>>(`/clicks`, {referrer});
  }
}