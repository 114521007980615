import styled from 'styled-components/macro'
import { backgroundColor, backgroundTextColor, textColor } from 'styles'

export const TimerStyled = styled.div`
  text-align: center;
  display: inline-block;
  border: double 2px transparent;
  border-radius: 30px;
  background-image: linear-gradient(${backgroundColor}, ${backgroundColor}),
    radial-gradient(circle at top left, ${backgroundTextColor}, #bbbec5);
  background-origin: border-box;
  background-clip: content-box, border-box;
  color: ${textColor};
  margin: 30px auto;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

export const TimerInner = styled.div`
  padding: 20px 30px 10px 30px;

  > div {
    font-family: 'Nizzoli';
    font-style: normal;
    font-size: 19px;
    margin-bottom: 10px;
  }
`

export const TimerGrid = styled.div`
  display: grid;
  grid-template-columns: 60px 6px 60px 6px 60px 6px 60px;
  grid-gap: 20px;

  @media (max-width: 1023px) {
    grid-template-columns: 40px 6px 40px 6px 40px 6px 40px;
    width: 310px;
    margin: auto;
  }

  > div {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    line-height: 48px;

    @media (max-width: 1023px) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  > div > p {
    font-size: 12px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 auto;
  }
`
