import { Button } from 'app/components/Button/Button.controller'
import { HomeButton } from 'app/components/HomeButton/HomeButton.view'
import { Input } from 'app/components/Input/Input.controller'
import { useState } from 'react'
import { Box, Card } from 'styles'

// prettier-ignore
import { FlashListCard, FlashListContainer, FlashListResult, FlashListStyled } from './FlashList.style'
import data from './data.json'

export const FlashListView = () => {
  const [address, setAddress] = useState('')
  const [status, setStatus] = useState<boolean | undefined>(undefined)

  const handleSearch = () => {
    const found = data.filter((row) => row === address)
    console.log(found)
    if (found && found.length > 0) setStatus(true)
    else setStatus(false)
  }

  return (
    <FlashListStyled>
      <HomeButton />
      <h1>Flash Sale Results</h1>
      <div>
        The Flash Sale is on a first-come, first-served basis.
        <br />
        The chronological order determines whether you are eligible for the Flash Sale or not.
        <br />
        <br />
        The list below indicates the list of eligible adresses for the Flash Sale only. <br />
        Check your eligibility by searching the address where you will receive your CLAP.
        <br />
        If you don't find your address, this means you're not eligible for the Flash Sale.
        <br />
        <br />
        <br />
        <br />
      </div>

      <Box display="flex" alignItems="center" justifyContent="center">
        <FlashListCard>
          {data.map((row) => (
            <div>{row}</div>
          ))}
          {/* <FlashListContainer>
            <Input
              name="walletAddress"
              type="text"
              id="walletAddress"
              value={address}
              onChange={(value) => setAddress(value as string)}
              onBlur={() => {}}
              placeholder="Enter your address"
            />

            <Button type="button" text="Check now" icon="arrow" large onClick={() => handleSearch()} />

            {(status === true || status === false) && (
              <FlashListResult>
                {status === true && <h1>Congrats, you are in the Flash Sale</h1>}
                {status === false && <h1>Sorry, you are not in the Flash Sale</h1>}
              </FlashListResult>
            )}
          </FlashListContainer> */}
        </FlashListCard>
      </Box>
    </FlashListStyled>
  )
}
