import styled from 'styled-components/macro'
import { Page } from 'styles'

export const FlashListStyled = styled(Page)`
  width: 100%;
  background-image: url('/images/bg-shapes.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;

  > h1 {
    margin: 50px auto 50px auto;
    color: #fff;
    display: block;
    font-family: 'Nizzoli';
    font-weight: 900;
    font-size: 70px;
  }
`

export const FlashListContainer = styled.div`
  > button {
    margin: 30px auto 0 auto;
    display: block;
  }
`

export const FlashListResult = styled.div`
  > h1 {
    color: #fff;
  }
`

export const FlashListCard = styled.div`
  display: block;
  margin: auto;
  padding: 50px;
  background: radial-gradient(
    90.16% 143.01% at 15.32% 21.04%,
    rgba(235, 236, 240, 0.2) 0%,
    rgba(235, 236, 240, 0.0447917) 77.08%,
    rgba(235, 236, 240, 0) 100%
  );
  backdrop-filter: blur(14px);
  border-radius: 40px;
  width: 960px;
  text-align: left;
  border: 2px solid;
  border-image-source: radial-gradient(80.38% 222.5% at -13.75% -12.36%, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    radial-gradient(80.69% 208.78% at 108.28% 112.58%, #ffffff 0%, rgba(18, 30, 52, 0) 100%);

  overflow: scroll;

  > div {
    word-break: break-all;
  }
`
