import styled from 'styled-components/macro'
import { Page } from 'styles'

export const AffiliateStyled = styled(Page)`
  width: 100%;
  background-image: url('/images/bg-shapes.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  word-break: break-word;

  > h1 {
    margin-bottom: 30px;
  }

  > h2 {
    margin-bottom: 10px;
  }

  h1 {
    color: white;
  }
`

export const AffiliateGrid = styled.div`
  width: 80%;
  max-width: 1240px;
  margin: 30px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media (max-width: 1023px) {
    grid-template-columns: auto;
    grid-gap: 20px;
  }
`
