import { combineReducers } from 'redux'

import { addressData, AddressState } from './address';
import { formData, FormState } from './form';
import { icoProgress, IcoProgressState } from './icoProgress'
import { investor, InvestorState } from './investor'
import { loading, LoadingState } from './loading'
import { referrer, ReferrerState } from './referrer'
import { toaster, ToasterState } from './toaster'

export const reducers = combineReducers({
  loading,
  toaster,
  investor,
  icoProgress,
  referrer,
  formData,
  addressData
})

export interface State {
  loading: LoadingState
  toaster: ToasterState
  investor: InvestorState
  icoProgress: IcoProgressState
  referrer: ReferrerState,
  formData: FormState,
  addressData: AddressState
}
