export const SET_FORM_DATA = 'SET_FORM_DATA'

export const setFormData = (firstname: string, lastname: string, email: string, emailConfirmation: string) => (dispatch: any) => {
  dispatch({
    type: SET_FORM_DATA,
    firstname,
    lastname,
    email,
    emailConfirmation
  })
}
