import { HomeButton } from 'app/components/HomeButton/HomeButton.view'
import { Phase } from 'enum/Phase.enum'
import { AddressState } from 'reducers/address'
import { FormState } from 'reducers/form'
import { IcoProgressState } from 'reducers/icoProgress'
import { Box } from 'styles'
import { DISCORD_LINK } from 'utils/links'

// prettier-ignore
import { KycFinishedCard, KycFinishedDiscordButton, KycFinishedDiscordIcon, KycFinishedDiscordText, KycFinishedStyled, KycFinishedSubBox, KycFinishedSubTitle, KycFinishedTitle, KycFinishedTxt } from './KycFinished.style'

interface KycFinishedViewProps {
  icoProgress: IcoProgressState
  formData: FormState
  addressData: AddressState
}
export const KycFinishedView = ({ icoProgress, formData, addressData }: KycFinishedViewProps) => {
  return (
    <KycFinishedStyled>
      <HomeButton />
      <KycFinishedCard>
        <div>
          <img src="/images/ico-finished.png" alt="Ico Finished logo" />
        </div>
        <KycFinishedTitle>
          Congratulations, <br /> you are eligible for the ICO! <br />
          <Box marginBottom={24}>
            <KycFinishedSubTitle>(You should have received a confirmation email.)</KycFinishedSubTitle>
          </Box>
          <KycFinishedSubBox>
            <KycFinishedTitle>Don’t forget</KycFinishedTitle>
            <KycFinishedTxt>Remember these info to register on sale day (take a pic!)</KycFinishedTxt>
            <Box>
              <KycFinishedTxt>
                First Name: <b>{formData.firstname}</b>
              </KycFinishedTxt>
              <KycFinishedTxt>
                Last Name: <b>{formData.lastname}</b>
              </KycFinishedTxt>
              <KycFinishedTxt>
                Email Address: <b>{formData.email}</b>
              </KycFinishedTxt>
              <KycFinishedTxt>
                Wallet Address: <b>{addressData.address}</b>
              </KycFinishedTxt>
            </Box>
          </KycFinishedSubBox>
          <br />
          <p>Come back on</p>
          {icoProgress.phase === Phase.FLASH_SALE && <span>Jan 27th 2022 at 05:08:00 PM UTC</span>}
          {icoProgress.phase === Phase.PUBLIC_SALE && <span>Jan 30th 2022 at 11:00:00 AM UTC</span>}
          <p>
            for the {icoProgress.phase === Phase.FLASH_SALE && <span>Flash Sale</span>}
            {icoProgress.phase === Phase.PUBLIC_SALE && <span>Public Sale</span>}
          </p>
        </KycFinishedTitle>
        <br />
        <br />
        <KycFinishedDiscordButton href={DISCORD_LINK} target="_blank">
          <KycFinishedDiscordText>Follow us on Discord</KycFinishedDiscordText>
          <KycFinishedDiscordIcon>
            <use xlinkHref={`/icons/sprites.svg#discord`} />
          </KycFinishedDiscordIcon>
        </KycFinishedDiscordButton>
      </KycFinishedCard>
    </KycFinishedStyled>
  )
}
