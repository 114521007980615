import { SET_ADDRESS } from "actions/address"

export interface AddressState {
  address: string,
}

const addressDefaultState: AddressState = {
  address: '',
}

export function addressData(state = addressDefaultState, action: any): AddressState {
  switch (action.type) {
    case SET_ADDRESS:
      return {
        address: action.address
      }
    default:
      return state
  }
}
