import styled from 'styled-components/macro'
import { backgroundColor, Box, Page, primaryColor } from 'styles'

export const FormStyled = styled(Page)`
  width: 100%;
  background-image: url('/images/bg-shapes.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const FormInfo = styled.p`
  font-family: Roboto;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;

  & a {
    color: #0085ff;
    font-weight: bold;
  }
`

export const FormLabel = styled.label`
  display: inline-block;
  margin-bottom: 12px;
  font-weight: bold;
  text-align: left;
`

export const FormSubmit = styled.div`
  text-align: center;
`

export const FormTitle = styled.h1`
  font-family: 'Nizzoli';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 576px) {
    font-size: 1.05rem;
  }
`

export const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 16px;
  width: 409px;
  @media (max-width: 500px) {
    width: 280px;
  }
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FormSubTitle = styled.p`
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  margin: 0;
`

export const FormText = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;

  & a {
    text-decoration: underline;
  }
`

export const FormWarning = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;

  label {
    display: flex;
    justify-content: center;
    align-items: start;
  }

  span {
    max-width: 460px;
  }
`

export const FormAddressInputContainer = styled(FormInputContainer)`
  margin-top: 30px;
`
export const FormInputCheckbox = styled.input`
  background: ${primaryColor};
  border: 0.15em solid ${primaryColor};
  border-radius: 0.15em;
  margin-right: 7px;
`
export const FormButtonGoBackIcon = styled.svg`
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: sub;
  stroke: ${backgroundColor};
  transform: rotate(180deg);
  cursor: pointer;
`

export const FormContainerTitle = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
