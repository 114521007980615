import { IcoPhase } from './../models/IcoPhase.model';
import Axios from 'axios';
import { ApiResponse } from 'models/ApiResponse.model';
import { IcoProgress } from 'models/IcoProgress.model';

export namespace IcoApi {

  export const getProgress = () => {
    return Axios.get<ApiResponse<IcoProgress>>('/ico/progress');
  }

  export const getParams = () => {
    return Axios.get<ApiResponse<IcoPhase>>(`/ico/params/`)
  }
}