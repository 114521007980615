import { useSelector } from 'react-redux'
import { State } from 'reducers'

import { KycFinishedView } from './KycFinished.view'

export const KycFinished = () => {
  const icoProgress = useSelector((state: State) => state.icoProgress)
  const formData = useSelector((state: State) => state.formData)
  const addressData = useSelector((state: State) => state.addressData)

  return <KycFinishedView icoProgress={icoProgress} formData={formData} addressData={addressData} />
}
