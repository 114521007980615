import React from 'react'
import { DISCORD_LINK, LINKEDIN_LINK, MEDIUM_LINK, TELEGRAM_LINK, TWITTER_LINK } from 'utils/links'
import { FooterContainer, FooterElement } from './Footer.style'

export const FooterView = () => {
  const socialNetworks = [
    {
      name: 'Discord',
      icon: '../icons/discord-white.svg',
      link: DISCORD_LINK,
    },
    {
      name: 'Telegram',
      icon: '../icons/telegram-white.svg',
      link: TELEGRAM_LINK,
    },
    {
      name: 'Medium',
      icon: '../icons/medium-white.svg',
      link: MEDIUM_LINK,
    },
    {
      name: 'Twitter',
      icon: '../icons/twitter-white.svg',
      link: TWITTER_LINK,
    },
    {
      name: 'Linkedin',
      icon: '../icons/linkedin-white.svg',
      link: LINKEDIN_LINK,
    },
  ]
  return (
    <FooterContainer>
      <FooterElement>
        <img src="../images/footer-cardashift.svg" alt="cardashift" className="w-56" />
      </FooterElement>
      <FooterElement>
        Empower the visionary ventures that will create a sustainable and prosperous future.
        <br />
        <br />
        <a href="https://cardashift.com/cardashift_whitepaper.pdf">White Paper</a>&nbsp;&nbsp;&nbsp;
        <a href="https://cardashift.com/cardashift_overview.pdf">Overview</a>&nbsp;&nbsp;&nbsp;
        <a href="https://cardashift.com/cardashift_deck.pdf">Slide Deck</a>&nbsp;&nbsp;&nbsp;
        <a href="https://cardashift.com/terms.pdf">Terms and Conditions</a>
      </FooterElement>
      <FooterElement>
        {socialNetworks.map(({ name, icon, link }) => {
          return (
            <a href={link} target="_blank" rel="noreferrer" key={name}>
              <img src={icon} alt="name" className="mr-16 md:mr-8 md:pr-2" />
            </a>
          )
        })}
      </FooterElement>
    </FooterContainer>
  )
}
