import styled from 'styled-components/macro'
import { Page, progressColor } from 'styles'

export const LandingStyled = styled(Page)`
  width: 100%;
  background-image: url('/images/bg-bubble-alt.png');
  background-repeat: no-repeat;
  background-color: #151e34;
  background-size: contain;
  text-align: center;
  background-position: center;

  @media (min-width: 1500px) {
    background-size: cover;
  }
`

export const LandingLineContainer = styled.div`
  margin-top: 100px;

  @media (max-width: 800px) {
    margin-top: 80px;
  }
`

export const LandingTitle = styled.h1`
  font-family: 'Nizzoli';
  font-weight: 900;
  font-size: 70px;
  color: #ffffff;
  max-width: 700px;

  @media (max-width: 800px) {
    font-size: 50px;
  }
`

export const LandingTitleLarger = styled.h1`
  font-family: 'Nizzoli';
  font-weight: 900;
  font-size: 70px;
  color: #ffffff;
  max-width: 900px;

  @media (max-width: 800px) {
    font-size: 50px;
  }

  > div:nth-child(1) {
    margin-bottom: 40px;
  }
`

export const LandingFlashSaleTitle = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24%;
  color: #ffffff;
  text-align: left;
  margin-bottom: 16px;
  margin-left: 5px;
`

export const LandingParagraph = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`

export const LandingSpace = styled.div`
  margin-top: 0px;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`

export const LandingTableContainer = styled.div`
  overflow-x: auto;
  margin: 60px 0;

  > img {
    width: 1064px;
  }
`

export const LandingTable = styled.div`
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 1064px;

  @media (max-width: 800px) {
    min-width: 700px;
  }
`

export const LandingLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 14px;

  & div {
    flex: calc(100 / 5);
    font-weight: bold;
  }
`

export const LandingRow = styled.div`
  padding: 24px 0;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 20px;
  border: 3px solid #c2c5cb;
  background: radial-gradient(
    90.16% 143.01% at 15.32% 21.04%,
    rgba(235, 236, 240, 0.2) 0%,
    rgba(235, 236, 240, 0.0447917) 77.08%,
    rgba(235, 236, 240, 0) 100%
  );
  backdrop-filter: blur(25px);

  & div {
    flex: calc(100 / 5);

    &:first-child {
      text-align: left;
      flex: calc(100 / 5 - 40px);
      padding-left: 40px;

      span {
        &:last-child {
          margin-top: 4px;
          display: block;
          font-weight: bold;
          font-size: 15px;
        }
      }
    }
  }
`
export const LandingProgressBar = styled.div`
  background: #121e34;
  border: 4px solid #121e34;
  box-shadow: inset -4px -4px 4px rgba(58, 68, 93, 0.5), inset 4px 4px 4px #060c18;
  border-radius: 20px;
`

export const LandingProgress = styled.div<{
  width: number
}>`
  background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), ${progressColor};
  background-blend-mode: soft-light, normal;
  border-radius: 20px;
  height: 24px;
  min-width: ${(props) => (props.width === 0 ? '0%' : '4.5%')};
  width: ${(props) => props.width + '%'};
  transition: width 0.5s ease-in;
  transition: background-color 0.5s ease-in;
`

export const LandingProgressRowText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

export const LandingProgressContainer = styled.div`
  position: relative;
  max-width: 532px;
  margin: auto;
  margin-bottom: 84px;
  width: 75%;
`

export const LandingTooltipContainer = styled.div<{ left: number }>`
  margin-top: 20px;
  position: absolute;
  left: ${(props) => props.left - 2 + '%'};
`

export const LandingTooltipBox = styled.div`
  background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), ${progressColor};
  background-blend-mode: soft-light, normal;
  box-shadow: 10px 10px 20px #050c1b;
  border-radius: 8px;
  width: 123px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -56.5px;
`

export const LandingProgressTooltipTriangle = styled.img`
  position: absolute;
  left: 0;
  width: 24px;
  height: 9px;
  top: 0;
  margin-top: -9px;
  margin-left: -7px;
`
export const LandingProgressText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  text-align: center;
`

export const LandingDiscordButton = styled.a`
  background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #ff7a00;
  background-blend-mode: soft-light, normal;
  border: 2px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: -10px -10px 20px rgba(8, 21, 45, 0.4), 5px 5px 15px rgba(5, 12, 27, 0.6);
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 236px;
  height: 56px;
`

export const LandingDiscordIcon = styled.svg`
  height: 24px;
  display: inline-block;
  vertical-align: sub;
  cursor: pointer;
  margin-left: 8px;
  max-width: 25px;
`
export const LandingDiscordText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #ffffff;
`

export const LandingSocialMediaRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 24px;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
`

export const LandingCustomLink = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LandingCustomLinkSocialButton = styled(LandingCustomLink)`
  margin: 12px;
  position: relative;
  z-index: 1;
`

export const LandingCustomLinkDiscord = styled(LandingCustomLink)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 24px 24px;
`

export const LandingSocialMediaButton = styled.button`
  background: #ebecf0;
  border: 2px solid rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  box-shadow: -5px -5px 10px rgba(8, 21, 45, 0.6), 5px 5px 10px rgba(5, 12, 27, 0.6);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
`

export const LandingWarning = styled.div`
  color: #ff7a00;
  margin: 20px auto;
  max-width: 500px;
  text-align: center;
`
