import styled from 'styled-components/macro'
import { Page } from 'styles'

export const EligibleStyled = styled(Page)`
  width: 100%;
  background-image: url('/images/bg-shapes.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
`

export const EligibleTitle = styled.h1`
  font-family: Nizzoli;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  color: #ffffff;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 576px) {
    font-size: 1.05rem;
  }
`

export const EligibleText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 28px 0;
`

export const WarningText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ff7a00;
  margin: 28px auto;

  > a {
    color: #ff7a00;
    text-decoration: underline;
  }
`

export const EligibleAddressBlock = styled.div`
  max-width: 402px;
  padding: 19px 24px;
  background: #121e34;
  box-shadow: 5px 5px 15px rgba(5, 12, 27, 0.6);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-wrap: anywhere;
  margin: auto;
  margin-bottom: 44px;
  word-break: break-all;
`

export const EligibleAddressText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
`

export const EligibleCopyIcon = styled.svg`
  height: 32px;
  display: inline-block;
  vertical-align: sub;
  cursor: pointer;
  margin-left: 24px;
  max-width: 33px;
`

export const EligibleBoldText = styled.span`
  font-weight: 700;
`

export const EligibleTextStart = styled(EligibleText)`
  text-align: start;
  margin-bottom: 0;
`

export const EligibleProgressText = styled.p<{ color?: string }>`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => (props.color ? props.color : 'unset')};
`

export const EligibleTextParticipation = styled.div`
  margin-top: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
`

export const EligibleProgressBar = styled.div`
  background: #121e34;
  border: 4px solid #121e34;
  box-shadow: inset -4px -4px 4px rgba(58, 68, 93, 0.5), inset 4px 4px 4px #060c18;
  border-radius: 20px;
`

export const EligibleProgress = styled.div<{
  width: number
  color: string
}>`
  background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%);
  background-color: ${(props) => props.color};
  background-blend-mode: soft-light, normal;
  border-radius: 20px;
  height: 24px;
  min-width: ${(props) => (props.width === 0 ? '0%' : '4.5%')};
  width: ${(props) => props.width + '%'};
  transition: width 0.5s ease-in;
  transition: background-color 0.5s ease-in;
`
export const EligibleProgressContainer = styled.div`
  position: relative;
  margin-bottom: 78px;
`

export const EligibleTooltipContainer = styled.div<{ left: number }>`
  margin-top: 20px;
  position: absolute;
  left: ${(props) => props.left + '%'};
`

export const EligibleTooltipBox = styled.div`
  background: #121e34;
  box-shadow: 10px 10px 20px #050c1b;
  border-radius: 8px;
  width: 72px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -36px;
`

export const EligibleProgressTooltipTriangle = styled.img`
  position: absolute;
  left: 0;
  width: 24px;
  height: 9px;
  top: 0;
  margin-top: -9px;
  margin-left: -12px;
`
export const EligibleAdaSentBackText = styled(EligibleProgressText)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  text-align: start;
  margin-top: 20px;
`

export const EligibleAdaLostText = styled(EligibleProgressText)`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  text-align: end;
  margin-top: 20px;
`

export const EligibleProgressRowText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`
