import { Button } from 'app/components/Button/Button.controller'
import { HomeButton } from 'app/components/HomeButton/HomeButton.view'
import { Input } from 'app/components/Input/Input.controller'
import { useState } from 'react'
import { Box, Card } from 'styles'

// prettier-ignore
import { FlashResolveContainer, FlashResolveResult, FlashResolveStyled } from './FlashResolve.style'
import data from './data.json'
import { Link } from 'react-router-dom'

export const FlashResolveView = () => {
  const [address, setAddress] = useState('')
  const [status, setStatus] = useState<boolean | undefined>(undefined)

  const handleSearch = () => {
    const found = data.filter((row) => row === address)
    console.log(found)
    if (found && found.length > 0) setStatus(true)
    else setStatus(false)
  }

  return (
    <FlashResolveStyled>
      <HomeButton />
      <h1>Flash Sale Results</h1>
      <div>
        The Flash Sale is on a first-come, first-served basis.
        <br />
        The chronological order determines whether you are eligible for the Flash Sale or not.
        <br />
        <br />
        Check your eligibility by entering your recipient CLAP address.
        <br />
        Enter your recipient CLAP address
        <br />
        <br />
        <br />
        <br />
      </div>

      <Box display="flex" alignItems="center" justifyContent="center">
        <Card>
          <FlashResolveContainer>
            <Input
              name="walletAddress"
              type="text"
              id="walletAddress"
              value={address}
              onChange={(value) => setAddress(value as string)}
              onBlur={() => {}}
              placeholder="Enter your address"
            />

            <Button type="button" text="Check now" icon="arrow" large onClick={() => handleSearch()} />

            {(status === true || status === false) && (
              <FlashResolveResult>
                {status === true && <h1>Congratulation! This address is eligible for the Flash Sale. </h1>}
                {status === false && <h1>Sorry. This address is not eligible for the Flash Sale.</h1>}
              </FlashResolveResult>
            )}
          </FlashResolveContainer>
        </Card>
      </Box>

      <div>
        <br />
        <br />
        The complete list is available here:
        <br />
        <u>
          <Link to="/flashsale-list">https://ico.cardashift.com/flashsale-list</Link>
        </u>
      </div>
    </FlashResolveStyled>
  )
}
