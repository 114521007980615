export const SET_ICO_PROGRESS_DATA = 'SET_ICO_PROGRESS_DATA'

export const setIcoProgress = (phase: number, kyc: number, funding: number) => (dispatch: any) => {
  dispatch({
    type: SET_ICO_PROGRESS_DATA,
    phase,
    kyc,
    funding,
  })
}
