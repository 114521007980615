import { ReferralApi } from 'api/referral.api'
import { Referrer } from 'models/Referrer.model'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AffiliateView } from './Affiliate.view'

export const Affiliate = () => {
  const { referrer } = useParams()
  const [refData, setRefData] = useState<Referrer>({
    clicks: undefined,
    referees: undefined,
    amount_flash: undefined,
    amount_public: undefined,
  })

  useEffect(() => {
    ;(async function getRefData() {
      try {
        const response = await ReferralApi.getReferrer(referrer)
        setRefData(response.data?.DATA)
      } catch (e: any) {}
    })()
  }, [referrer])

  return <AffiliateView refData={refData} referrer={referrer} />
}
