import { store } from 'app/App';
import { HIDE_TOASTER, SHOW_TOASTER } from 'app/components/Toaster/Toaster.actions';
import Axios, { AxiosError } from 'axios';
import { ApiResponse } from 'models/ApiResponse.model';

const apiUrl = process.env.REACT_APP_ENV === 'dev' ? 'https://api-dev.cardashift.com/api' : 'https://api.cardashift.com/api'

Axios.defaults.baseURL = apiUrl;

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError<ApiResponse<null>>) => {
 
    if (!error.response?.data?.MESSAGE) {
      store.dispatch({
        type: SHOW_TOASTER,
        status: 'error',
        title: 'Error',
        message: 'Unexpected error',
      })
      
    } else {
      store.dispatch({
        type: SHOW_TOASTER,
        status: 'error',
        title: 'Error',
        message: error.response?.data?.MESSAGE,
      })
    }
    setTimeout(() => {
      store.dispatch({
        type: HIDE_TOASTER,
      })
    }, 4000)
    return Promise.reject(error);
  },
);
