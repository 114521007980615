import styled from 'styled-components/macro'
import { Card, Page } from 'styles'

export const KycFinishedCard = styled(Card)`
  position: relative;

  @media (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`
export const KycFinishedStyled = styled(Page)`
  width: 100%;
  background-image: url('/images/bg-shapes.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
`

export const KycFinishedTitle = styled.h1`
  font-family: 'Nizzoli';
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  margin: 0;

  > p {
    margin: auto;
    font-weight: normal;
  }
`

export const KycFinishedLink = styled.a`
  font-weight: bold;
`
export const KycFinishedDiscordButton = styled.a`
  background: linear-gradient(135.37deg, rgba(0, 0, 0, 0.4) 4.29%, rgba(255, 255, 255, 0.4) 95.6%), #ff7a00;
  background-blend-mode: soft-light, normal;
  border: 2px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: -10px -10px 20px rgba(8, 21, 45, 0.4), 5px 5px 15px rgba(5, 12, 27, 0.6);
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 236px;
  height: 56px;
  margin: auto;
`

export const KycFinishedDiscordIcon = styled.svg`
  height: 24px;
  display: inline-block;
  vertical-align: sub;
  cursor: pointer;
  margin-left: 8px;
  max-width: 25px;
`
export const KycFinishedDiscordText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #ffffff;
`

export const KycFinishedTxt = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  word-break: break-all;

  > b {
    word-break: break-all;
  }
`

export const KycFinishedSubTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
`

export const KycFinishedSubBox = styled(Card)`
  width: 100%;
  text-align: left;
`
