import { SET_FORM_DATA } from './../actions/form';

export interface FormState {
    firstname: string,
    lastname: string,
    email: string,
    emailConfirmation: string,
}

const formDefaultState: FormState = {
  firstname: '',
  lastname: '',
  email: '',
  emailConfirmation: ''
}

export function formData(state = formDefaultState, action: any): FormState {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        firstname: action.firstname,
        lastname: action.lastname,
        email: action.email,
        emailConfirmation: action.emailConfirmation
      }
    default:
      return state
  }
}
