import styled, { keyframes } from 'styled-components/macro'
import {
  textColor,
  backgroundTextColor,
  borderColor,
  downColor,
  primaryColor,
  subTextColor,
  shadowColor,
  upColor,
} from '../../../styles'

export const InputStyled = styled.div`
  position: relative;
  margin-bottom: 5px;
  width: 100%;
`

export const InputComponent = styled.input`
  max-width: 409px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  height: 48px;
  padding: 12px 16px;
  text-align: start;
  background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #ebecf0;
  background-blend-mode: soft-light, normal;
  border: 1.19854px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset -5.9927px -5.9927px 11.9854px #fafbff, inset 5.9927px 5.9927px 11.9854px #a6abbd;
  border-radius: 48px;
  border-color: ${borderColor};
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  will-change: border-color, box-shadow;
  background-color: ${textColor};
  color: ${shadowColor};

  ::placeholder {
    color: ${shadowColor};
  }

  :disabled {
    background: ${backgroundTextColor};
    color: ${subTextColor};
  }

  :hover {
    border-color: ${primaryColor}7F;
  }

  .error {
    border-color: ${downColor};

    :focus {
      box-shadow: 0 0 0 2px ${downColor}7F;
    }
  }

  .success {
    border-color: ${upColor};

    :focus {
      box-shadow: 0 0 0 2px ${upColor}7F;
    }
  }
`
const zoomIn = keyframes`
  from {
    transform:scale(.2);
    opacity:0
  }
  to {
    transform:scale(1);
    opacity:1
  }
`

export const InputStatus = styled.div`
  display: block;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 1;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  pointer-events: none;
  will-change: transform, opacity;

  &.error {
    background-image: url('/icons/input-error.svg');
    animation: ${zoomIn} 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }

  &.success {
    background-image: url('/icons/input-success.svg');
    animation: ${zoomIn} 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }
`

export const InputIcon = styled.svg`
  display: block;
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  pointer-events: none;
  stroke: ${backgroundTextColor};
`

export const slideDown = keyframes`
  from {
    transform: translate3d(0, -10px, 0);
    opacity:0
  }
  to {
    transform: translate3d(0, 0px, 0);
    opacity:1
  }
`

export const InputErrorMessage = styled.div`
  color: ${downColor};
  line-height: 24px;
  will-change: transform, opacity;
  animation: ${slideDown} 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;

  &:first-letter {
    text-transform: uppercase;
  }
  a {
    text-decoration: underline;
    color: ${downColor};
  }
`

export const InputSpacer = styled.div`
  height: 10px;
`
