import styled from 'styled-components/macro'

export const ErrorFallbackStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 20px;

  span {
    font-weight: bold;
  }
`
